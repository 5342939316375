import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import TowerHorizontalLeftMenu from "./tower_horizontalLeftMenu";
import TowerHorizontalRightContainer from "./tower_horizontalRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { useTowerHorizontals } from "../../../hooks/api/tower-api/useTowerHorizontals";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HorizontalsModel from "../../../models/HorizontalModel";

export default function TowerHorizontalPage() {
  const { userData } = useContext(UserContext);
  const { towerId, towerImages, segmentsSelected, trussesSelected, horizontalSelected, setHorizontalSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation,
    isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchHorizontals } = useTowerHorizontals("get");
  const { act: saveHorizontals } = useTowerHorizontals("post");

  const trussesTypes = trussesSelected.map(truss => truss.trussType);
  const trussesBracingTypes = trussesSelected.map(truss => truss.bracingType);
  const trussesWithHorizontals = trussesSelected.map(truss => truss.hasSuperiorHorizontal);

  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(trussesSelected.length > 0 ? 1 : "");
  const [checkedSegments, setCheckedSegments] = useState(
    Array.from({ length: trussesWithHorizontals.length }, (_, i) => []));
  const [inputValues, setInputValues] = useState(new HorizontalsModel(horizontalSelected).horizontals);

  useEffect(() => {
    loadHorizontalsData().then(response => {
      if (response.length === 0) {
        setInitialEmptyValues();
      } else {
        const horizontalsModel = new HorizontalsModel(response);
        setHorizontalSelected(horizontalsModel.horizontals);
        setInputValues(horizontalsModel.horizontals);
      }
    });
  }, [towerId, userData.token]);

  const loadHorizontalsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchHorizontals(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados das horizontais.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [fetchHorizontals, towerId, userData.token]);

  function setInitialEmptyValues() {
    let initialData = [];
    for (let i = 0; i < trussesTypes.length; i++) {
      initialData[i] = (trussesTypes[i] === "Treliça V" || trussesTypes[i] === "Treliça K")
        ? {
          segmentId: segmentsSelected[i].segmentId || "",
          trussType: trussesTypes[i],
          bracingType: trussesBracingTypes[i],
          isSuperiorHorizontalDifferent: trussesWithHorizontals[i] === "Não" ? "NA" : trussesWithHorizontals[i],
        } : {
          segmentId: segmentsSelected[i].segmentId || "",
          trussType: trussesTypes[i],
          bracingType: trussesBracingTypes[i],
          profileType: horizontalSelected[i]?.profileType || "",
          profileSteel: horizontalSelected[i]?.profileSteel || "",
          profileDimensions: horizontalSelected[i]?.profileDimensions || "",
          profileDiameter: horizontalSelected[i]?.profileDiameter || "",
          profileThickness: horizontalSelected[i]?.profileThickness || "",
          profileFlange: horizontalSelected[i]?.profileFlange || "",
          profileWeb: horizontalSelected[i]?.profileWeb || "",
          numConnectionBolts: horizontalSelected[i]?.numConnectionBolts || "",
          diameterConnectionBolts: horizontalSelected[i]?.diameterConnectionBolts || "",
          boltsSteel: horizontalSelected[i]?.boltsSteel || "",
          reinforcementDimensions: horizontalSelected[i]?.reinforcementDimensions || "",
          reinforcementDiameter: horizontalSelected[i]?.reinforcementDiameter || "",
          reinforcementThickness: horizontalSelected[i]?.reinforcementThickness || "",
          reinforcementFlange: horizontalSelected[i]?.reinforcementFlange || "",
          reinforcementWeb: horizontalSelected[i]?.reinforcementWeb || "",
          isSuperiorHorizontalDifferent: trussesWithHorizontals[i] === "Não"
            ? "NA" : horizontalSelected[i]?.isSuperiorHorizontalDifferent || "",
        };

      initialData[i].superiorHorizontalData = trussesWithHorizontals[i] === "Não" ? {} : {
        profileType: horizontalSelected[i]?.superiorHorizontalData?.profileType || "",
        profileSteel: horizontalSelected[i]?.superiorHorizontalData?.profileSteel || "",
        profileDimensions: horizontalSelected[i]?.superiorHorizontalData?.profileDimensions || "",
        profileDiameter: horizontalSelected[i]?.superiorHorizontalData?.profileDiameter || "",
        profileThickness: horizontalSelected[i]?.superiorHorizontalData?.profileThickness || "",
        profileFlange: horizontalSelected[i]?.superiorHorizontalData?.profileFlange || "",
        profileWeb: horizontalSelected[i]?.superiorHorizontalData?.profileWeb || "",
        numConnectionBolts: horizontalSelected[i]?.superiorHorizontalData?.numConnectionBolts || "",
        diameterConnectionBolts: horizontalSelected[i]?.superiorHorizontalData?.diameterConnectionBolts || "",
        boltsSteel: horizontalSelected[i]?.superiorHorizontalData?.boltsSteel || "",
        reinforcementDimensions: horizontalSelected[i]?.superiorHorizontalData?.reinforcementDimensions || "",
        reinforcementDiameter: horizontalSelected[i]?.superiorHorizontalData?.reinforcementDiameter || "",
        reinforcementThickness: horizontalSelected[i]?.superiorHorizontalData?.reinforcementThickness || "",
        reinforcementFlange: horizontalSelected[i]?.superiorHorizontalData?.reinforcementFlange || "",
        reinforcementWeb: horizontalSelected[i]?.superiorHorizontalData?.reinforcementWeb || "",
      };
    }

    const model = new HorizontalsModel(initialData);
    setHorizontalSelected(model.horizontals);
    setInputValues(model.horizontals);
  }

  const fieldsToCheck = ["profileType", "profileSteel", "profileDimensions", "profileDiameter", 
    "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", 
    "diameterConnectionBolts", "reinforcementDimensions", "reinforcementDiameter", 
    "reinforcementThickness", "reinforcementFlange", "reinforcementWeb", "isSuperiorHorizontalDifferent",
    "superiorHorizontalData.profileType", "superiorHorizontalData.profileSteel", 
    "superiorHorizontalData.profileDimensions", "superiorHorizontalData.profileDiameter", 
    "superiorHorizontalData.profileFlange", "superiorHorizontalData.profileWeb", 
    "superiorHorizontalData.profileThickness", "superiorHorizontalData.boltsSteel", 
    "superiorHorizontalData.numConnectionBolts", "superiorHorizontalData.diameterConnectionBolts",
    "superiorHorizontalData.reinforcementDimensions", "superiorHorizontalData.reinforcementDiameter", 
    "superiorHorizontalData.reinforcementThickness", "superiorHorizontalData.reinforcementFlange", 
    "superiorHorizontalData.reinforcementWeb"
  ];

  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], horizontalSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value, segmentIndex) => {
    const index = segmentIndex !== undefined ? segmentIndex : segmentSelectedInMenu - 1;
    const horizontalsModel = new HorizontalsModel(inputValues);

    horizontalsModel.updateHorizontal(inputValues[index].segmentId, field, value);
    setInputValues(horizontalsModel.horizontals);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const handleSuperiorHorizontalChange = useCallback((field, value) => {
    const horizontalsModel = new HorizontalsModel(inputValues);

    if (horizontalsModel.horizontals[segmentSelectedInMenu - 1].isSuperiorHorizontalDifferent === "")
      horizontalsModel.checkForEmptyTrussesValues(horizontalsModel.horizontals[segmentSelectedInMenu - 1],
        trussesWithHorizontals[segmentSelectedInMenu - 1]);

    horizontalsModel.updateSuperiorHorizontal(inputValues[segmentSelectedInMenu - 1].segmentId, field, value);
    setInputValues(horizontalsModel.horizontals);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const setEqualSegment = (index) => {
    const currentValues = new HorizontalsModel(inputValues);
    const selectedSegmentIndex = segmentSelectedInMenu - 1;
    const targetSegmentLabel = `Trecho ${index + 1}`;

    const fieldsToUpdate = ["profileType", "profileDimensions", "profileDiameter", "profileThickness", 
      "profileFlange", "profileWeb", "profileSteel", "numConnectionBolts", "diameterConnectionBolts", 
      "boltsSteel", "reinforcementDimensions", "reinforcementDiameter", "reinforcementThickness", 
      "reinforcementFlange", "reinforcementWeb", "isSuperiorHorizontalDifferent", "superiorHorizontalData"];

    const reinforcementFieldsToUpdate = ["profileType", "profileSteel", "profileDimensions", "profileDiameter",
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", "diameterConnectionBolts",
      "reinforcementDimensions", "reinforcementDiameter", "reinforcementThickness", "reinforcementFlange",
      "reinforcementWeb"];
    
    if (checkedSegments[selectedSegmentIndex].includes(targetSegmentLabel)) {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = newChecked[selectedSegmentIndex].filter(i => i !== targetSegmentLabel);
        return newChecked;
      });

      fieldsToUpdate.forEach(field => {
        const isSuperiorHorizontalDifferent = trussesWithHorizontals[index] === "Não"
          ? "NA"
          : ((trussesTypes[index] === "Treliça V" || trussesTypes[index] === "Treliça K"
            || (trussesTypes[index] === "Treliça H" && trussesBracingTypes[index] === "B")) && "Sim");

        return field === "isSuperiorHorizontalDifferent"
          ? currentValues.updateHorizontal(inputValues[index].segmentId, field, isSuperiorHorizontalDifferent)
          : currentValues.updateHorizontal(inputValues[index].segmentId, field, "");
      });
      currentValues.updateHorizontal(inputValues[index].segmentId, "superiorHorizontalData", "empty");
    } else {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = [...newChecked[selectedSegmentIndex], targetSegmentLabel];
        return newChecked;
      });

      fieldsToUpdate.forEach(field => {
        if (trussesTypes[index] === "Treliça K"
          || (trussesTypes[index] === "Treliça V" && trussesWithHorizontals[index] === "Sim")
          || (trussesTypes[index] === "Treliça H" && trussesBracingTypes[index] === "B")) {
          currentValues.updateHorizontal(
            inputValues[index].segmentId, "isSuperiorHorizontalDifferent", "Sim");
          reinforcementFieldsToUpdate.forEach(f => currentValues.updateSuperiorHorizontal(
            inputValues[index].segmentId, f, inputValues[selectedSegmentIndex].superiorHorizontalData[f]));
        } else {
          if (trussesWithHorizontals[index] === "Sim") {
            currentValues.updateHorizontal(inputValues[index].segmentId, field, inputValues[selectedSegmentIndex][field]);
            if (field === "isSuperiorHorizontalDifferent" && inputValues[selectedSegmentIndex].isSuperiorHorizontalDifferent === "Sim") {
              reinforcementFieldsToUpdate.forEach(f => currentValues.updateSuperiorHorizontal(
                inputValues[index].segmentId, f, inputValues[selectedSegmentIndex].superiorHorizontalData[f])
              );
            }
          } else {
            field !== "isSuperiorHorizontalDifferent"
              ? currentValues.updateHorizontal(inputValues[index].segmentId, field, inputValues[selectedSegmentIndex][field])
              : currentValues.updateHorizontal(inputValues[index].segmentId, field, "NA");
          }
        }
      });
    }
    
    setInputValues(currentValues.horizontals);
  };

  async function updateHorizontalsData() {
    const horizontalsModel = new HorizontalsModel(inputValues);
    if (!horizontalsModel.validate(trussesWithHorizontals)) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveHorizontals(horizontalsModel.toJSON(), towerId, userData.token);
      const updatedModel = new HorizontalsModel(updatedData);
      setHorizontalSelected(updatedModel.horizontals);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das horizontais atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das horizontais.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Horizontais"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateHorizontalsData}
      >
        <TowerHorizontalLeftMenu
          trussesWithHorizontals={trussesWithHorizontals}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          handleInputChange={(field, value, index) => handleInputChange(field, value, index)}
          checkedSegments={checkedSegments}
          setEqualSegment={(index, checked) => setEqualSegment(index, checked)}
          trussesTypes={trussesTypes}
          trussesBracingTypes={trussesBracingTypes}
        />
      </LeftMenu>
      <RightContainer>
        <TowerHorizontalRightContainer 
          trussesWithHorizontals={trussesWithHorizontals}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          handleSuperiorHorizontalChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
          trussesTypes={trussesTypes}
          trussesSelected={trussesSelected}
          towerImages={towerImages}
          trussesBracingTypes={trussesBracingTypes}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
