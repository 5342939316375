import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import towerImg from "../../../assets/images/towerImages/tower.svg";
import triangularSectionImg from "../../../assets/images/towerImages/tower-section-triagular.svg";
import squareSectionImg from "../../../assets/images/towerImages/tower-section-square.svg";
import CustomRadioButton from "../../common/custom-radio-buttons";
import platformsImages from "../../../assets/images/towerImages/platformsTypes/platforms-images";
import colors from "../../../constants/color-constants";

export default function TowerPlatformRightContainer({
  platforms,
  inputValues, 
  platformSelectedInMenu, 
  setPlatformSelectedInMenu,
  handleInputChange, 
  addPlatform,
  towerSection,
}) {
  function handleClick(index) {
    if (platformSelectedInMenu === "") return;
    setPlatformSelectedInMenu(index);
  }

  function renderPlatformsTypes() {
    return (
      <div>
        <SectionImg
          selected={platformSelectedInMenu === "" ? false :
            inputValues[platformSelectedInMenu - 1]?.platformType === "Interna"}
          onClick={() => handleInputChange("platformType", "Interna")}
        >
          <CustomRadioButton
            name="platformType"
            value="Interna"
            disabled={platformSelectedInMenu === ""}
            checked={inputValues[platformSelectedInMenu - 1]?.platformType === "Interna"}
            onClick={() => handleInputChange("platformType", "Interna")}
          />
          <img src={towerSection === "Quadrada"
            ? platformsImages.platformInternalSquared
            : platformsImages.platformInternalTriangular
          } width={"115px"} alt="Interna"/>
        </SectionImg>

        <SectionImg
          selected={platformSelectedInMenu === "" ? false :
            inputValues[platformSelectedInMenu - 1]?.platformType === "Externa"}
          onClick={() => handleInputChange("platformType", "Externa")}
        >
          <CustomRadioButton
            name="platformType"
            value="Externa"
            disabled={platformSelectedInMenu === ""}
            checked={inputValues[platformSelectedInMenu - 1]?.platformType === "Externa"}
            onClick={() => handleInputChange("platformType", "Externa")}
          />
          <img src={towerSection === "Quadrada"
            ? platformsImages.platformExternalSquared
            : platformsImages.platformExternalTriangular
          } width={"115px"} alt="Externa"/>
        </SectionImg>
      </div>
    );
  }

  return (
    <Container>
      <OptionsContainer>
        <h2>Tipo de Plataforma</h2>

        {renderPlatformsTypes()}

        <div>
          <ElevationContainer>
            <h2>Elevação da<br/>Plataforma (m)</h2>
            <CustomInput
              disabled={platformSelectedInMenu===""}
              value={inputValues[platformSelectedInMenu - 1]?.platformElevation}
              onChange={newValue => handleInputChange("platformElevation", newValue)}
              isNumber={true}
              unit={"m"}
            />
          </ElevationContainer>
        </div>
      </OptionsContainer>

      <TowerGeometryImgContainer>
        <h2>Plataformas na Torre</h2>
        <PositionalContainer>
          <TowerImg>
            <img src={towerImg} alt={""} />
          </TowerImg>

          <HeightsContainer>
            {platforms.map((platform, index) => (
              <InputContainer key={index}>
                <div className="row">
                  <h3>{inputValues[index]?.platformType || ""}</h3>
                  <CustomInput
                    readOnly={platformSelectedInMenu === ""}
                    value={inputValues[index]?.platformElevation}
                    onChange={(newValue) => handleInputChange("platformElevation", newValue, index + 1)}
                    onClick={() => handleClick(index + 1)}
                    isNumber={true}
                    unit={"m"}
                  />
                </div>
                <div className="line" />
              </InputContainer>
            ))}
          </HeightsContainer>

          <AddPlatformButton onClick={addPlatform}>
            <p>{"Adicionar plataforma"}</p>
            <div> + </div>
          </AddPlatformButton>
        </PositionalContainer>
      </TowerGeometryImgContainer>
    </Container>
  );  
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: ${colors.kcLightGray};
  border-radius: 6px;
  min-width: 250px;
  width: min-content;
  max-height: calc(100vh - 245px);
  height: min-content;
  box-sizing: border-box;
  gap: 20px;

  h2 {
    color: ${colors.kcTextColor};
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  >div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 20px;
  }
`;

const SectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 165px;
  height: 165px;
  padding: 15px;
  background-color: ${colors.kcWhiteColor};
  border-radius: 8px;
  border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
  box-sizing: border-box;

  img {
    width: ${(props) => props.width};
  }
`;

const ElevationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  width: 150px;
  min-height: min-content;
  border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
  box-sizing: border-box;

  h2 {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.kcTextColor};
    line-height: 1.2;
    letter-spacing: 0.15px;
    margin-bottom: 6px;
    font-family: 'Roboto', sans-serif;
  }

  input {
    width: 100%;
    border: 1px solid ${colors.kcBorderGray};
    background-color: ${colors.kcWhiteColor};
    height: 40px;
    padding: 6px;
    border-radius: 8px;
    font-size: 12px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }
`;

const TowerGeometryImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 450px;
  min-height: 650px;
  height: 100%;
  max-height: calc(100vh - 245px);
  padding: 20px;
  gap: 20px;
  border-radius: 6px;
  background-color: ${colors.kcLightGray};
  font-family: 'Roboto', sans-serif;

  h2 {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.kcBlackColor};
    font-family: 'Roboto', sans-serif;
  }
`;

const PositionalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;

const TowerImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  img {
    margin: 20px 40px 10px;
    object-fit: contain;
    height: 540px;
  }
`;

const HeightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px 0 80px;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  
  .line {
    width: ${(props) => props.width || "200px"};
    height: 1px;
    margin-right: 8px;
    background-color: ${colors.kcBorderGray};
    z-index: 2;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }

  h3 {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: ${colors.kcBlackColor};
    font-family: 'Roboto', sans-serif;
  }
  
  input {
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 30px;
    padding: 3px;
    border-radius: 6px;
    border: 1px solid ${colors.kcBorderGray};
    background-color: ${colors.kcWhiteColor};
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
`;

const AddPlatformButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 175px;
  height: 40px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 10px 20px;
  background-color: ${colors.kcButtonLightGray};
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background-color: ${colors.kcButtonGray};
  }

  p {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }

  div {
    align-items: center;
    justify-content: center;
    padding: 0 3px;
    border-radius: 30px;
    background-color: ${colors.kcWhiteColor};
    font-size: 18px;
  }
`;
