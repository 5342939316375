import { createContext, useState } from "react";
import GeometryModel from "../models/GeometryModel";
import TrussesModel from "../models/TrussModel";
import SectionsModel from "../models/SectionModel";
import PlatformsModel from "../models/PlatformModel";
import WindModel from "../models/WindModel";
import AntennasModel from "../models/AntennaModel";
import MountsModel from "../models/MountModel";
import DiagonalsModel from "../models/DiagonalModel";
import HorizontalsModel from "../models/HorizontalModel";
import RedundantsModel from "../models/RedundantModel";
import DiagonalLockingsModel from "../models/DiagonalLockingModel";
import HorizontalLockingsModel from "../models/HorizontalLockingModel";
import AnchorsModel from "../models/AnchorModel";

const TowerContext = createContext();

const TowerStorage = ({ children }) => {
  const [towerData, setTowerData] = useState({});
  const [towerId, setTowerId] = useState(null);
  const [towerImages, setTowerImages] = useState(null);
  const [segmentsSelected, setSegmentsSelected] = useState([]);
  const [geometrySelected, setGeometrySelected] = useState(new GeometryModel());
  const [trussesSelected, setTrussesSelected] = useState(new TrussesModel().trusses);
  const [sectionsSelected, setSectionsSelected] = useState(new SectionsModel().sections);
  const [platformsSelected, setPlatformsSelected] = useState(new PlatformsModel().platforms);
  const [windSelected, setWindSelected] = useState(new WindModel().wind);
  const [antennaSelected, setAntennaSelected] = useState(new AntennasModel().antennasData);
  const [mountSelected, setMountSelected] = useState(new MountsModel().mounts);
  const [diagonalSelected, setDiagonalSelected] = useState(new DiagonalsModel().diagonals);
  const [horizontalSelected, setHorizontalSelected] = useState(new HorizontalsModel().horizontals);
  const [redundantSelected, setRedundantSelected] = useState(new RedundantsModel().redundants);
  const [diagonalLockingSelected, setDiagonalLockingSelected] = useState(new DiagonalLockingsModel().diagonalLockings);
  const [horizontalLockingSelected, setHorizontaLockingSelected] = useState(new HorizontalLockingsModel().horizontalLockings);
  const [anchorsSelected, setAnchorsSelected] = useState(new AnchorsModel().anchor);

  return (
    <TowerContext.Provider
      value={{
        towerData,
        setTowerData,
        towerId,
        setTowerId,
        towerImages,
        setTowerImages,
        segmentsSelected,
        setSegmentsSelected,
        geometrySelected,
        setGeometrySelected,
        trussesSelected,
        setTrussesSelected,
        sectionsSelected,
        setSectionsSelected,
        platformsSelected,
        setPlatformsSelected,
        windSelected,
        setWindSelected,
        antennaSelected,
        setAntennaSelected,
        mountSelected,
        setMountSelected,
        diagonalSelected,
        setDiagonalSelected,
        horizontalSelected,
        setHorizontalSelected,
        redundantSelected,
        setRedundantSelected,
        diagonalLockingSelected,
        setDiagonalLockingSelected,
        horizontalLockingSelected,
        setHorizontaLockingSelected,
        anchorsSelected,
        setAnchorsSelected,
      }}
    >
      {children}
    </TowerContext.Provider>
  );
};

export { TowerContext, TowerStorage };
