import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import TowerDiagonalLeftMenu from "./tower_diagonalLeftMenu";
import TowerDiagonalRightContainer from "./tower_diagonalRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { useTowerDiagonals } from "../../../hooks/api/tower-api/useTowerDiagonals";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DiagonalsModel from "../../../models/DiagonalModel";

export default function TowerDiagonalPage() {
  const { userData } = useContext(UserContext);
  const { towerId, towerImages, segmentsSelected, geometrySelected, trussesSelected,
    diagonalSelected, setDiagonalSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchDiagonals } = useTowerDiagonals("get");
  const { act: saveDiagonals } = useTowerDiagonals("post");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const segments = nSegments > 0
    ? Array.from({ length: nSegments }, (_, i) => `Trecho ${i + 1}`) : [];
  const trussesTypes = trussesSelected.map(truss => truss.trussType);

  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(nSegments > 0 ? 1 : "");
  const [checkedSegments, setCheckedSegments] = useState(
    Array.from({ length: nSegments }, (_, i) => []));
  const [inputValues, setInputValues] = useState(new DiagonalsModel(diagonalSelected).diagonals);

  useEffect(() => {
    loadDiagonalsData().then(response => {
      if (response.length === 0) {
        setInitialEmptyValues();
      } else {
        const diagonalsModel = DiagonalsModel.fromJSON(JSON.stringify(response));
        setDiagonalSelected(diagonalsModel.diagonals);
        setInputValues(diagonalsModel.diagonals);
      }
    });
  }, [towerId, userData.token]);

  const loadDiagonalsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchDiagonals(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados das diagonais.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchDiagonals, towerId, userData.token]);

  function setInitialEmptyValues() {
    const initialData = new DiagonalsModel(
      [...Array(nSegments)].map((_, i) => ({
        segmentId: segmentsSelected[i].segmentId || "",
        trussType: trussesTypes[i],
        profileType: diagonalSelected[i]?.profileType || "",
        profileSteel: diagonalSelected[i]?.profileSteel || "",
        profileDimensions: diagonalSelected[i]?.profileDimensions || "",
        profileDiameter: diagonalSelected[i]?.profileDiameter || "",
        profileThickness: diagonalSelected[i]?.profileThickness || "",
        profileFlange: diagonalSelected[i]?.profileFlange || "",
        profileWeb: diagonalSelected[i]?.profileWeb || "",
        boltsSteel: diagonalSelected[i]?.boltsSteel || "",
        numConnectionBolts: diagonalSelected[i]?.numConnectionBolts || "",
        diameterConnectionBolts: diagonalSelected[i]?.diameterConnectionBolts || "",
        reinforcementDimensions: diagonalSelected[i]?.reinforcementDimensions || "",
        reinforcementDiameter: diagonalSelected[i]?.reinforcementDiameter || "",
        reinforcementThickness: diagonalSelected[i]?.reinforcementThickness || "",
        reinforcementFlange: diagonalSelected[i]?.reinforcementFlange || "",
        reinforcementWeb: diagonalSelected[i]?.reinforcementWeb || "",
        isInferiorDiagonalDifferent: diagonalSelected[i]?.isInferiorDiagonalDifferent || "",
        inferiorDiagonalData: diagonalSelected[i]?.inferiorDiagonalData || {},
      }))
    );
    setInputValues(initialData.diagonals);
  }

  const fieldsToCheck = ["profileType", "profileSteel", "profileDimensions", "profileDiameter", 
    "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", 
    "diameterConnectionBolts", "reinforcementDimensions", "reinforcementDiameter", 
    "reinforcementThickness", "reinforcementFlange", "reinforcementWeb", "isInferiorDiagonalDifferent",
    "inferiorDiagonalData.profileType", "inferiorDiagonalData.profileDimensions", 
    "inferiorDiagonalData.profileSteel", "inferiorDiagonalData.profileDiameter", 
    "inferiorDiagonalData.profileThickness", "inferiorDiagonalData.profileFlange", 
    "inferiorDiagonalData.profileWeb", "inferiorDiagonalData.boltsSteel", 
    "inferiorDiagonalData.numConnectionBolts", "inferiorDiagonalData.diameterConnectionBolts",
    "inferiorDiagonalData.reinforcementDimensions", "inferiorDiagonalData.reinforcementDiameter", 
    "inferiorDiagonalData.reinforcementThickness", "inferiorDiagonalData.reinforcementFlange", 
    "inferiorDiagonalData.reinforcementWeb"
  ];
  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], diagonalSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value) => {
    const diagonalsModel = new DiagonalsModel(inputValues);
    diagonalsModel.updateDiagonal(inputValues[segmentSelectedInMenu - 1].segmentId, field, value);
    setInputValues(diagonalsModel.diagonals);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const handleInferiorDiagonalChange = useCallback((field, value) => {
    if (inputValues[segmentSelectedInMenu - 1].isInferiorDiagonalDifferent !== "Sim") return;
    const diagonalsModel = new DiagonalsModel(inputValues);
    diagonalsModel.updateInferiorDiagonal(inputValues[segmentSelectedInMenu - 1].segmentId, field, value);
    setInputValues(diagonalsModel.diagonals);
  }, [inputValues, setInputValues, segmentSelectedInMenu]);

  const setEqualSegment = (index) => {
    const currentValues = new DiagonalsModel(inputValues);
    const selectedSegmentIndex = segmentSelectedInMenu - 1;
    const targetSegmentLabel = `Trecho ${index + 1}`;

    const fieldsToUpdate = ["profileType", "profileSteel", "profileDimensions", "profileDiameter",
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", "diameterConnectionBolts",
      "reinforcementDimensions", "reinforcementDiameter", "reinforcementThickness", "reinforcementFlange",
      "reinforcementWeb", "isInferiorDiagonalDifferent"];

    const reinforcementFieldsToUpdate = ["profileType", "profileSteel", "profileDimensions", "profileDiameter",
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", "diameterConnectionBolts",
      "reinforcementDimensions", "reinforcementDiameter", "reinforcementThickness", "reinforcementFlange",
      "reinforcementWeb"];

    if (checkedSegments[selectedSegmentIndex].includes(targetSegmentLabel)) {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = newChecked[selectedSegmentIndex].filter(i => i !== targetSegmentLabel);
        return newChecked;
      });
      fieldsToUpdate.forEach(field => {
        currentValues.updateDiagonal(inputValues[index].segmentId, field, "");
        currentValues.updateDiagonal(inputValues[index].segmentId, "inferiorDiagonalData", "empty");
      });
    } else {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = [...newChecked[selectedSegmentIndex], targetSegmentLabel];
        return newChecked;
      });
      fieldsToUpdate.forEach(field => {
        if (trussesTypes[index] === "Treliça X") {
          currentValues.updateDiagonal(inputValues[index].segmentId, field, inputValues[selectedSegmentIndex][field]);
          if (field === "isInferiorDiagonalDifferent" && inputValues[selectedSegmentIndex].isInferiorDiagonalDifferent === "Sim") {
            reinforcementFieldsToUpdate.forEach(f => currentValues.updateInferiorDiagonal(
              inputValues[index].segmentId, f, inputValues[selectedSegmentIndex].inferiorDiagonalData[f])
            );
          }
        } else {
          if (field !== "isInferiorDiagonalDifferent") {
            currentValues.updateDiagonal(inputValues[index].segmentId, field, inputValues[selectedSegmentIndex][field]);
          }
        }
      });
    }

    setInputValues(currentValues.diagonals);
  };

  async function updateDiagonalsData() {
    const diagonalsModel = new DiagonalsModel(inputValues);
    diagonalsModel.setInferiorDiagonalDataEqual();
    if (!diagonalsModel.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveDiagonals(diagonalsModel.toJSON(), towerId, userData.token);
      setDiagonalSelected(updatedData.diagonals);
      setInputValues(updatedData.diagonals);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das diagonais atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das diagonais.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Diagonais"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateDiagonalsData}
      >
        <TowerDiagonalLeftMenu 
          segments={segments}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          trussesTypes={trussesTypes}
          setEqualSegment={setEqualSegment}
          checkedSegments={checkedSegments}
        />
      </LeftMenu>
      <RightContainer>
        <TowerDiagonalRightContainer
          nSegments={nSegments}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          handleInferiorDiagonalChange={(field, value) => handleInferiorDiagonalChange(field, value)}
          trussesSelected={trussesSelected}
          towerImages={towerImages}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
