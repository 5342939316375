import styled from "styled-components";
import { useCallback, useContext, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TowerCalculationLeftMenu from "./tower_calculationLeftMenu";
import TowerCalculationRightContainer from "./tower_calculationRightContainer";
import { useTowerCalculateByStrap } from "../../../hooks/api/tower-api/useTowerCalculateByStrap";
import { useTower } from "../../../hooks/api/tower-api/useTowerApi";
import useAssignTowerData from "../../../hooks/useAssignTowerData";
import TowerModel from "../../../models/TowerModel";
import TrussesModel from "../../../models/TrussModel";
import SectionsModel from "../../../models/SectionModel";
import PlatformsModel from "../../../models/PlatformModel";
import WindModel from "../../../models/WindModel";
import AntennasModel from "../../../models/AntennaModel";
import MountsModel from "../../../models/MountModel";
import DiagonalsModel from "../../../models/DiagonalModel";
import HorizontalsModel from "../../../models/HorizontalModel";
import RedundantsModel from "../../../models/RedundantModel";
import DiagonalLockingsModel from "../../../models/DiagonalLockingModel";
import HorizontalLockingsModel from "../../../models/HorizontalLockingModel";
import AnchorsModel from "../../../models/AnchorModel";

export default function TowerCalculationPage() {
  const { userData } = useContext(UserContext);
  const { setIsUpdatedWithoutCalculation, projectIdSelected, projectNameSelected, isLoading, setIsLoading } = useContext(ProjectContext);
  const { geometrySelected, trussesSelected, sectionsSelected, platformsSelected, windSelected,
    antennaSelected, mountSelected, diagonalSelected, horizontalSelected, redundantSelected,
    diagonalLockingSelected, horizontalLockingSelected, anchorsSelected } = useContext(TowerContext);

  const assignTowerData = useAssignTowerData();
  const trussesWithHorizontals = trussesSelected.map(truss => truss.hasSuperiorHorizontal);

  const { act: calculateTower } = useTowerCalculateByStrap("post");
  const { act: getTower } = useTower("get");

  const [strapMessage, setStrapMessage] = useState();

  function validateTowerData() {
    const towerModel = new TowerModel();
    towerModel.updateField("projectId", projectIdSelected);
    towerModel.updateField("projectName", projectNameSelected);
    towerModel.updateField("geometry", geometrySelected);
    towerModel.updateField("trusses", new TrussesModel(trussesSelected));
    towerModel.updateField("sections", new SectionsModel(sectionsSelected));
    towerModel.updateField("platforms", new PlatformsModel(platformsSelected));
    towerModel.updateField("winds", new WindModel(windSelected));
    towerModel.updateField("antennas", new AntennasModel(antennaSelected));
    towerModel.updateField("mounts", new MountsModel(mountSelected));
    towerModel.updateField("diagonals", new DiagonalsModel(diagonalSelected));
    towerModel.updateField("horizontals", new HorizontalsModel(horizontalSelected));
    towerModel.updateField("redundants", new RedundantsModel(redundantSelected));
    towerModel.updateField("diagonalLockings", new DiagonalLockingsModel(diagonalLockingSelected));
    towerModel.updateField("horizontalLockings", new HorizontalLockingsModel(horizontalLockingSelected));
    towerModel.updateField("anchors", new AnchorsModel(anchorsSelected));
    return towerModel.validate(trussesWithHorizontals);
  }

  const loadTowerData = useCallback(async() => {
    try {
      return getTower(projectIdSelected, userData.token);
    } catch (error) {
      console.error(error);
    }
  }, [getTower, projectIdSelected, userData.token]);

  const postCalculateStrap = useCallback(async() => {
    const body = { projectId: projectIdSelected, isDownload: true };
    try {
      return await calculateTower(body, userData.token);
    } catch (error) {
      console.error(error);
    }
  }, [calculateTower, projectIdSelected, userData.token]);

  async function calculateTowerData() {
    setIsLoading(true);
    try {
      const towerResponse = await loadTowerData();
      if (!towerResponse) return toast.error("Erro ao buscar os dados da torre.");

      assignTowerData(towerResponse);

      if (!validateTowerData()) return toast.error("Erro ao validar os dados da torre.");

      const strapResponse = await postCalculateStrap();
      setIsUpdatedWithoutCalculation(false);
      toast.success("Cálculos da torre executados com sucesso");

      let elapsedSeconds = 5;
      let interval = setInterval(() => {
        elapsedSeconds--;
        if(elapsedSeconds === 0) {
          clearInterval(interval);
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
      }, 5000);

      if(strapResponse.type !== "application/x-rar-compressed" && strapResponse.type !== "application/zip") {
        await blobToString(strapResponse);
        return;
      }

      await blobToDownload(strapResponse);
      return true;
    } catch (error) {
      console.error("Error during calculation:", error);
      toast.error("Erro ao calcular os dados da torre.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  async function blobToString(strap) {
    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const jsonData = JSON.parse(reader.result);
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsText(strap);
    });
    return setStrapMessage([...data]);
  }

  async function blobToDownload(strap) {
    const file = strap;
    const fileUrl = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "strapfiles.rar";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(fileUrl);
  }

  return (
    <Container>
      <LeftMenu
        title="Calcular"
        isInputModified={false}
        isLoading={isLoading}
        updateData={() => {}}
      >
        <TowerCalculationLeftMenu
          calculateTowerData={() => calculateTowerData()}
        />
      </LeftMenu>
      <RightContainer>
        <TowerCalculationRightContainer/>
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
