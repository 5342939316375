import styled from "styled-components";
import colors from "../../../constants/color-constants";

export default function TowerCalculationLeftMenu({ calculateTowerData }) {
  return (
    <Container>
      <CalculateButton onClick={calculateTowerData}>Calcular</CalculateButton>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CalculateButton = styled.button`
    display: flex;
    width: 100%;
    height: 40px;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.kcOrange};
    border-radius: 6px;
    background-color: ${colors.kcOrange};
    color: ${colors.kcWhiteColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
`;
