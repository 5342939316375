import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import Accordion from "../../common/accordion-input";
import CustomCheckbox from "../../common/custom-checkbox";
import { mountProfileTypes, tubeProfileData, mountConnectionType,
  boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";

export default function MountLeftMenu({
  segments,
  inputValues,
  segmentSelectedInMenu,
  setSegmentSelectedInMenu,
  handleInputChange,
  setEqualSegment,
  checkedSegments,
  checkedConnections,
}) {
  return (
    <>
      <InputMenu>
        <h2>Trecho Atual</h2>
        <select
          className="highlightColor"
          value={segmentSelectedInMenu}
          onChange={(e) => setSegmentSelectedInMenu(e.target.value)}>
          <option value="" key="segment-empty">Selecione um trecho</option>
          {[...Array(segments.length).keys()].map((i) => (
            <option value={i + 1} key={`segment-${i + 1}`}>
              Trecho {i + 1}
            </option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Tipo de Perfil</h2>
        <select
          disabled={segmentSelectedInMenu === ""}
          value={segmentSelectedInMenu === "" ? "" : inputValues[segmentSelectedInMenu - 1]?.profileType ?? ""}
          onChange={(e) => handleInputChange("profileType", e.target.value)}
        >
          <option value="" key="profileType-empty">Selecione o tipo de perfil</option>
          {mountProfileTypes.map((type) => (
            <option value={type} key={`profileType-${type}`}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Aço do Perfil</h2>
        <select
          disabled={segmentSelectedInMenu === ""}
          value={segmentSelectedInMenu === "" ? "" : inputValues[segmentSelectedInMenu - 1]?.profileSteel ?? ""}
          onChange={(e) => handleInputChange("profileSteel", e.target.value)}
        >
          <option value="" key="profileSteel-empty">Selecione o aço do perfil</option>
          {inputValues[segmentSelectedInMenu - 1]?.profileType &&
            handleProfileSteelTypes(inputValues[segmentSelectedInMenu - 1]?.profileType)?.map((type) => (
              <option value={type} key={`profileSteel-${type}`}>{type}</option>
            ))}
        </select>
      </InputMenu>

      {(segmentSelectedInMenu !== "" && inputValues[segmentSelectedInMenu - 1]?.profileSteel) &&
        <InputMenu>
          <h2>Dimensões do Perfil</h2>
          {
            inputValues[segmentSelectedInMenu - 1]?.profileType === "L - Cantoneira" &&
            <AutocompleteInput
              inputValue={inputValues[segmentSelectedInMenu - 1]?.profileDimensions || ""}
              handleInputChange={(field, value) => handleInputChange(field, value)}
              list={handleProfileDimensions(inputValues[segmentSelectedInMenu - 1]?.profileType) || []}
              field={"profileDimensions"}
              placeholder={"Selecione as dimensões do perfil"}
            />
          }
          {
            inputValues[segmentSelectedInMenu - 1]?.profileType === "T - Tubo Vazado" &&
            <div>
              <AutocompleteInput
                inputValue={inputValues[segmentSelectedInMenu - 1]?.profileDiameter || ""}
                handleInputChange={(field, value) => handleInputChange(field, value)}
                list={handleProfileDimensions(inputValues[segmentSelectedInMenu - 1]?.profileType,
                  inputValues[segmentSelectedInMenu - 1]?.profileSteel) || []}
                field={"profileDiameter"}
                placeholder={"Diâmetro"}
                label={"Diâmetro"}
              />

              <AutocompleteInput
                inputValue={inputValues[segmentSelectedInMenu - 1]?.profileThickness || ""}
                handleInputChange={(field, value) => handleInputChange(field, value)}
                list={inputValues[segmentSelectedInMenu - 1]?.profileDiameter
                  ? tubeProfileData[inputValues[segmentSelectedInMenu - 1]?.profileSteel]
                    [inputValues[segmentSelectedInMenu - 1]?.profileDiameter]
                  : []}
                field={"profileThickness"}
                placeholder={"Espessura"}
                label={"Espessura"}
              />
            </div>
          }
          {
            inputValues[segmentSelectedInMenu - 1]?.profileType === "BR - Barra Maciça" &&
            <AutocompleteInput
              inputValue={inputValues[segmentSelectedInMenu - 1]?.profileDiameter || ""}
              handleInputChange={(field, value) => handleInputChange(field, value)}
              list={handleProfileDimensions(inputValues[segmentSelectedInMenu - 1]?.profileType) || []}
              field={"profileDiameter"}
              placeholder={"Selecione o diâmetro do perfil"}
            />
          }
          {
            inputValues[segmentSelectedInMenu - 1]?.profileType === "O - Ômega" &&
            <>
              <div>
                <CustomInput
                  placeholder={"Aba (mm)"}
                  label={"Aba"}
                  value={inputValues[segmentSelectedInMenu - 1]?.profileFlange ?? ""}
                  onChange={(newValue) => handleInputChange("profileFlange", newValue)}
                  rawNumber={true}
                  unit={"mm"}
                />

                <CustomInput
                  placeholder={"Alma (mm)"}
                  label={"Alma"}
                  value={inputValues[segmentSelectedInMenu - 1]?.profileWeb ?? ""}
                  onChange={(newValue) => handleInputChange("profileWeb", newValue)}
                  rawNumber={true}
                  unit={"mm"}
                />
              </div>
              <div>
                <AutocompleteInput
                  inputValue={inputValues[segmentSelectedInMenu - 1]?.profileThickness || ""}
                  handleInputChange={(field, value) => handleInputChange(field, value)}
                  list={handleProfileDimensions(inputValues[segmentSelectedInMenu - 1]?.profileType) || []}
                  field={"profileThickness"}
                  placeholder={"Selecione a espessura do perfil"}
                  label={"Espessura"}
                />
              </div>
            </>
          }
        </InputMenu>
      }

      <InputMenu>
        <h2>Conexão no Montante</h2>
        <select
          disabled={segmentSelectedInMenu === ""}
          value={segmentSelectedInMenu === "" ? "" : inputValues[segmentSelectedInMenu - 1]?.mountConnection ?? ""}
          onChange={(e) => handleInputChange("mountConnection", e.target.value)}
        >
          <option value="" key="mountConnection-empty">Selecione a conexão no Montante</option>
          {mountConnectionType.map((type) => (
            <option value={type} key={`mountConnection-${type}`}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Aço dos Parafusos</h2>
        <select
          disabled={segmentSelectedInMenu === ""
            || inputValues[segmentSelectedInMenu - 1]?.mountConnection === "Não há"}
          value={segmentSelectedInMenu === "" || inputValues[segmentSelectedInMenu - 1]?.mountConnection === "Não há"
            ? "" : inputValues[segmentSelectedInMenu - 1]?.boltsSteel ?? ""}
          onChange={(e) => handleInputChange("boltsSteel", e.target.value)}
        >
          <option value="" key="boltsSteel-empty">Selecione o aço dos parafusos</option>
          {boltsSteel.map((type) => (
            <option value={type} key={`boltsSteel-${type}`}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Parafusos de Conexão</h2>
        <div>
          <CustomInput
            disabled={segmentSelectedInMenu === ""
              || inputValues[segmentSelectedInMenu - 1]?.mountConnection === "Não há"}
            placeholder={"Quantidade"}
            label={"Quantidade"}
            value={segmentSelectedInMenu === "" || inputValues[segmentSelectedInMenu - 1]?.mountConnection === "Não há"
              ? "" : inputValues[segmentSelectedInMenu - 1]?.numConnectionBolts ?? ""}
            onChange={(newValue) => handleInputChange("numConnectionBolts", newValue)}
            toFixed={0}
          />

          <CustomSelect
            disabled={segmentSelectedInMenu === ""
              || inputValues[segmentSelectedInMenu - 1]?.mountConnection === "Não há"}
            label={"Diâmetro"}
            value={segmentSelectedInMenu === "" || inputValues[segmentSelectedInMenu - 1]?.mountConnection === "Não há"
              ? "" : inputValues[segmentSelectedInMenu - 1]?.diameterConnectionBolts ?? ""}
            onChange={(e) => handleInputChange("diameterConnectionBolts", e)}
          >
            <option value="" key="diameterConnectionBolts-empty">Diâmetro</option>
            {boltsDiameter.map((type) => (
              <option value={type} key={`diameterConnectionBolts-${type}`}>{type}</option>
            ))}
          </CustomSelect>
        </div>
      </InputMenu>

      <InputMenu>
        <h2>Existe reforço no Montante?</h2>
        <select
          disabled={segmentSelectedInMenu === ""}
          value={segmentSelectedInMenu === "" ? "" : inputValues[segmentSelectedInMenu - 1]?.hasReinforcement ?? ""}
          onChange={(e) => handleInputChange("hasReinforcement", e.target.value)}
        >
          <option value="" key="hasReinforcement-empty">Informe se existe reforço no montante</option>
          <option value="Sim" key="hasReinforcement-yes">Sim</option>
          <option value="Não" key="hasReinforcement-no" >Não</option>
        </select>
      </InputMenu>

      <Accordion title={"Selecionar Trechos Iguais"}>
        {segmentSelectedInMenu !== "" && segments.map((_, index) => (
          Number(segmentSelectedInMenu) === index + 1 ? null :
            <SelectableOptionContainer key={`container-${index}`}>
              <CustomCheckbox
                id={`segment-${segmentSelectedInMenu}-target-${index}`}
                label={`Trecho ${index + 1}`}
                checked={checkedSegments[segmentSelectedInMenu - 1]?.includes(`Trecho ${index + 1}`) || false}
                onChange={() => setEqualSegment(false, index)}
                key={`segment-${index + 1}`}
              />
              <CustomCheckbox
                id={`connection-${segmentSelectedInMenu}-target-${index}`}
                label={`Conexão ${index + 1}`}
                checked={checkedConnections[segmentSelectedInMenu - 1]?.includes(`Conexão ${index + 1}`) || false}
                onChange={() => setEqualSegment(true, index)}
                key={`connection-${index + 1}`}
              />
            </SelectableOptionContainer>
        ))}
      </Accordion>
    </>
  );
};

const SelectableOptionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 10px;
    
    > div {
        width: 100%;
    }
`;

