import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import CustomCheckbox from "../../common/custom-checkbox";
import Accordion from "../../common/accordion-input";
import { profileTypes, tubeProfileData, boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";
import colors from "../../../constants/color-constants";

export default function TowerHorizontalLeftMenu({
  trussesWithHorizontals,
  inputValues,
  segmentSelectedInMenu,
  setSegmentSelectedInMenu,
  handleInputChange,
  checkedSegments,
  setEqualSegment,
  trussesTypes,
  trussesBracingTypes,
}) {
  const currentSegmentIndex = segmentSelectedInMenu === ""
    ? segmentSelectedInMenu : Number(segmentSelectedInMenu - 1);
  const currentSegment = currentSegmentIndex !== ""
    ? inputValues[segmentSelectedInMenu - 1] : null;

  function handleSegmentChange(value) {
    setSegmentSelectedInMenu(value);
    if (value !== "" && trussesWithHorizontals[value - 1] === "Sim"
      && (trussesTypes[value - 1] === "Treliça V"
        || (trussesTypes[value - 1] === "Treliça H" && trussesBracingTypes[value - 1] === "B"))) {
      handleInputChange("isSuperiorHorizontalDifferent", "Sim", Number(value) - 1);
    }
  }

  return (
    <>
      <InputMenu>
        <h2>Trecho Atual</h2>
        <select
          className="highlightColor"
          value={segmentSelectedInMenu}
          onChange={(e) => handleSegmentChange(e.target.value)}>
          <option value="">Selecione um trecho</option>
          {[...Array(trussesWithHorizontals.length).keys()].map((i) => (
            <option value={i + 1} key={`segment-${i + 1}`}>
              Trecho {i + 1}
            </option>
          ))}
        </select>
      </InputMenu>

      {
        (trussesTypes[segmentSelectedInMenu - 1] === "Treliça V"
          || trussesTypes[segmentSelectedInMenu - 1] === "Treliça K"
          || (trussesTypes[segmentSelectedInMenu - 1] === "Treliça H" && trussesBracingTypes[segmentSelectedInMenu - 1] === "B")
        ) ? <MessageTrussesV>
            <p>{"Não há horizontais principais nesse trecho."}</p>
          </MessageTrussesV>
          : <>
            <InputMenu>
              <h2>Tipo de Perfil</h2>
              <select
                disabled={segmentSelectedInMenu === ""}
                value={segmentSelectedInMenu === "" ? "" : currentSegment?.profileType ?? ""}
                onChange={(e) => handleInputChange("profileType", e.target.value)}
              >
                <option value="" key={"profileType-empty"}>Selecione o tipo de perfil</option>
                {profileTypes.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Aço do Perfil</h2>
              <select
                disabled={segmentSelectedInMenu === ""}
                value={segmentSelectedInMenu === "" ? "" : currentSegment?.profileSteel ?? ""}
                onChange={(e) => handleInputChange("profileSteel", e.target.value)}
              >
                <option value="" key={"profileSteel-empty"}>Selecione o aço do perfil</option>
                {currentSegment?.profileType && 
                  handleProfileSteelTypes(currentSegment?.profileType)?.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
              </select>
            </InputMenu>

            {(segmentSelectedInMenu !== "" && currentSegment?.profileSteel) && 
              <InputMenu>
                <h2>Dimensões do Perfil</h2>
                {
                  currentSegment?.profileType === "L - Cantoneira" &&
                  <AutocompleteInput
                    inputValue={currentSegment?.profileDimensions || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={handleProfileDimensions(currentSegment?.profileType) || []}
                    field={"profileDimensions"}
                    placeholder={"Selecione as dimensões do perfil"}
                  />
                }
                {
                  currentSegment?.profileType === "T - Tubo Vazado" &&
                  <div>
                    <AutocompleteInput
                      inputValue={currentSegment?.profileDiameter || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={currentSegment?.profileSteel
                        ? handleProfileDimensions(currentSegment.profileType, currentSegment?.profileSteel) : []}
                      field={"profileDiameter"}
                      placeholder={"Diâmetro"}
                      label={"Diâmetro"}
                    />

                    <AutocompleteInput
                      inputValue={currentSegment?.profileThickness || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={currentSegment?.profileDiameter
                        ? tubeProfileData[currentSegment?.profileSteel][currentSegment?.profileDiameter] : []}
                      field={"profileThickness"}
                      placeholder={"Espessura"}
                      label={"Espessura"}
                    />
                  </div>
                }
                {
                  currentSegment?.profileType === "BR - Barra Maciça" &&
                  <AutocompleteInput
                    inputValue={currentSegment?.profileDiameter || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={handleProfileDimensions(currentSegment.profileType)}
                    field={"profileDiameter"}
                    placeholder={"Selecione o diâmetro do perfil"}
                  />
                }
                {
                  currentSegment?.profileType === "U - Perfil U" &&
                  <>
                    <div>
                      <CustomInput
                        placeholder={"Aba (mm)"}
                        label={"Aba"}
                        value={currentSegment?.profileFlange ?? ""}
                        onChange={(newValue) => handleInputChange("profileFlange", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />

                      <CustomInput
                        placeholder={"Alma (mm)"}
                        label={"Alma"}
                        value={currentSegment?.profileWeb ?? ""}
                        onChange={(newValue) => handleInputChange("profileWeb", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />
                    </div>
                    <div>
                      <AutocompleteInput
                        inputValue={currentSegment?.profileThickness || ""}
                        handleInputChange={(field, value) => handleInputChange(field, value)}
                        list={handleProfileDimensions(currentSegment.profileType) || []}
                        field={"profileThickness"}
                        placeholder={"Espessura"}
                        label={"Espessura"}
                      />
                    </div>
                  </>
                }
              </InputMenu>
            }

            <InputMenu>
              <h2>Aço dos Parafusos</h2>
              <select
                disabled={segmentSelectedInMenu === ""}
                value={segmentSelectedInMenu === "" ? "" : currentSegment?.boltsSteel ?? ""}
                onChange={(e) => handleInputChange("boltsSteel", e.target.value)}
              >
                <option value="" key={"boltsSteel-empty"}>Selecione o aço dos parafusos</option>
                {boltsSteel.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Parafusos de Conexão</h2>
              <div>
                <CustomInput
                  disabled={segmentSelectedInMenu === ""}
                  placeholder={"Quantidade"}
                  label={"Quantidade"}
                  value={segmentSelectedInMenu === "" ? ""
                    : currentSegment?.numConnectionBolts ?? ""}
                  onChange={(newValue) => handleInputChange("numConnectionBolts", newValue)}
                  toFixed={0}
                />

                <CustomSelect
                  disabled={segmentSelectedInMenu === ""}
                  label={"Diâmetro"}
                  value={segmentSelectedInMenu === "" ? ""
                    : currentSegment?.diameterConnectionBolts ?? ""}
                  onChange={(e) => handleInputChange("diameterConnectionBolts", e)}
                >
                  <option value="" key={"diameterConnectionBolts-empty"}>Diâmetro</option>
                  {boltsDiameter.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
                </CustomSelect>
              </div>
            </InputMenu>

            {(segmentSelectedInMenu !== "" && currentSegment?.profileSteel) && 
              <InputMenu>
                <h2>Dimensões do Perfil Reforço</h2>
                {
                  currentSegment?.profileType === "L - Cantoneira" &&
                  <AutocompleteInput
                    inputValue={currentSegment?.reinforcementDimensions || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={["Não Há Reforço", ...handleProfileDimensions(currentSegment?.profileType)] || []}
                    field={"reinforcementDimensions"}
                    placeholder={"Dimensões"}
                  />
                }
                {
                  currentSegment?.profileType === "T - Tubo Vazado" &&
                  <div>
                    <AutocompleteInput
                      inputValue={currentSegment?.reinforcementDiameter || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={["Não Há Reforço", ...handleProfileDimensions(currentSegment.profileType,
                        currentSegment?.profileSteel)] || []}
                      field={"reinforcementDiameter"}
                      placeholder={"Diâmetro"}
                      label={"Diâmetro"}
                    />

                    <AutocompleteInput
                      inputValue={currentSegment?.reinforcementThickness || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={(currentSegment?.reinforcementDiameter
                        && currentSegment?.reinforcementDiameter !== "Não Há Reforço")
                        ? ["Não Há Reforço", ...tubeProfileData[currentSegment?.profileSteel]
                          [currentSegment?.reinforcementDiameter]]
                        : []}
                      field={"reinforcementThickness"}
                      placeholder={"Espessura"}
                      label={"Espessura"}
                    />
                  </div>
                }
                {
                  currentSegment?.profileType === "BR - Barra Maciça" &&
                  <AutocompleteInput
                    inputValue={currentSegment?.reinforcementDiameter || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={["Não Há Reforço", ...handleProfileDimensions(currentSegment.profileType)]}
                    field={"reinforcementDiameter"}
                    placeholder={"Diâmetro"}
                  />
                }
                {
                  currentSegment?.profileType === "U - Perfil U" &&
                  <>
                    <div>
                      <CustomInput
                        disabled={currentSegment?.reinforcementThickness === "Não Há Reforço"}
                        placeholder={"Aba (mm)"}
                        label={"Aba"}
                        value={currentSegment?.reinforcementFlange ?? ""}
                        onChange={(newValue) => handleInputChange("reinforcementFlange", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />

                      <CustomInput
                        disabled={currentSegment?.reinforcementThickness === "Não Há Reforço"}
                        placeholder={"Alma (mm)"}
                        label={"Alma"}
                        value={currentSegment?.reinforcementWeb ?? ""}
                        onChange={(newValue) => handleInputChange("reinforcementWeb", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />
                    </div>
                    <div>
                      <AutocompleteInput
                        inputValue={currentSegment?.reinforcementThickness || ""}
                        handleInputChange={(field, value) => handleInputChange(field, value)}
                        list={["Não Há Reforço", ...handleProfileDimensions(currentSegment.profileType)] || []}
                        field={"reinforcementThickness"}
                        placeholder={"Espessura"}
                        label={"Espessura"}
                      />
                    </div>
                  </>
                }
              </InputMenu>
            }

            {
              trussesWithHorizontals[segmentSelectedInMenu - 1] === "Sim" ?
                <InputMenu>
                  <h2>Horizontal superior é diferente da principal?</h2>
                  <select
                    disabled={segmentSelectedInMenu === ""}
                    value={segmentSelectedInMenu === "" ? "" : currentSegment?.isSuperiorHorizontalDifferent ?? ""}
                    onChange={(e) => handleInputChange("isSuperiorHorizontalDifferent", e.target.value)}
                  >
                    <option value="">Informe se a horizontal superior é diferente</option>
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </select>
                </InputMenu> : <></>
            }

            <Accordion title={"Selecionar Trechos Iguais"} >
              {
                segmentSelectedInMenu !== "" && trussesWithHorizontals.map((_, index) => (
                  (Number(segmentSelectedInMenu) !== index + 1) &&
                    <SelectableOptionContainer key={`container-${index}`}>
                      <CustomCheckbox
                        id={`segment${segmentSelectedInMenu}-target-${index}`}
                        label={`Trecho ${index + 1}`}
                        checked={checkedSegments[segmentSelectedInMenu - 1]?.includes(`Trecho ${index + 1}`)}
                        onChange={(e) => setEqualSegment(index)}
                        key={`segment-${index}`}
                      />
                    </SelectableOptionContainer >
                ))
              }
            </Accordion>  
          </>
      }
    </>
  );
};

const SelectableOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 10px;
`;

const MessageTrussesV = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 15px;

  p {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
  }
`;
