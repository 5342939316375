import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import colors from "../../../constants/color-constants";
import trussImages from "../../../assets/images/towerImages/trussTypes/trusses-images";

const TowerSilhouetteSections = ({
  images,
  sections,
  sectionHeights,
  geometryTotalHeight,
  geometryTopWidth,
  geometryBaseWidth,
  sectionSelectedInMenu,
  handleInputChange,
  trussesSelected,
  setTowerImages,
}) => {
  const trussesContainerRef = useRef(null);

  const [positionContainerHeight, setPositionContainerHeight] = useState(0);
  const [isZoomActive, setIsZoomActive] = useState(false);
  const [imagePaths, setImagePaths] = useState(images?.length === trussesSelected.length ? images : getTowerImages());

  useEffect(() => {
    if (trussesContainerRef.current) {
      setPositionContainerHeight(trussesContainerRef.current.clientHeight);
    }
  }, [isZoomActive, images, sections]);

  function getTowerImages() {
    const paths = trussesSelected?.map((value) => {
      if (!value?.trussType || !value?.mountDivisions || !value?.bracingType) return null;
      const imageName = `${value.trussType.charAt(value.trussType.length - 1)}${value.mountDivisions}${value.bracingType}`;
      return trussImages[value.trussType][imageName];
    });
    setTowerImages(paths);
    return paths;
  }

  function renderSectionsContainers() {
    if (sectionHeights.section1 === 0) return;

    const relativeHeightSection1 = sectionHeights?.section1 / (geometryTotalHeight * 1000);
    const relativeHeightSection2 = sectionHeights?.section2 / (geometryTotalHeight * 1000);
    const relativeHeightSection3 = sectionHeights?.section3 / (geometryTotalHeight * 1000);

    const middleHeightSection1 = positionContainerHeight * (relativeHeightSection1 / 2);
    const middleHeightSection2 = positionContainerHeight * (relativeHeightSection1 + relativeHeightSection2 / 2);
    const middleHeightSection3 = positionContainerHeight * (relativeHeightSection1 + relativeHeightSection2 + relativeHeightSection3 / 2);

    return (
      <>
        <SectionInputContainer top={middleHeightSection1}>
          <InputDisplay>
            Seção 1 <br /> H={sectionHeights?.section1?.toFixed(2) ?? "0.00"} m
          </InputDisplay>
        </SectionInputContainer>
        {
          sections.length > 1 &&
          <SectionInputContainer top={middleHeightSection2}>
            <InputDisplay>
              Seção 2 <br /> H={sectionHeights?.section2?.toFixed(2) ?? "0.00"} m
            </InputDisplay>
          </SectionInputContainer>
        }
        {
          sections.length > 2 &&
          <SectionInputContainer top={middleHeightSection3}>
            <InputDisplay>
              Seção 3 <br /> H={sectionHeights?.section3?.toFixed(2) ?? "0.00"} m
            </InputDisplay>
          </SectionInputContainer>
        }
      </>
    );
  }

  function renderHeightsContainers() {
    const relativeHeightSection1 = sectionHeights?.section1 / (geometryTotalHeight * 1000);
    const relativeHeightSection2 = sectionHeights?.section2 / (geometryTotalHeight * 1000);
    const relativeHeightSection3 = sectionHeights?.section3 / (geometryTotalHeight * 1000);

    const heightSection1 = positionContainerHeight * relativeHeightSection1 - 30;
    const heightSection2 = positionContainerHeight * (relativeHeightSection1 + relativeHeightSection2) - 30;
    const heightSection3 = positionContainerHeight * (relativeHeightSection1 + relativeHeightSection2 + relativeHeightSection3) - 62;

    return (
      <>
        <div>
          <InputContainer top={"2px"}>
            <div className="line"/>
            <CustomInput
              readOnly={true}
              value={geometryTotalHeight}
              onChange={newValue => newValue}
              isNumber={true}
              unit={"m"}
            />
            <CustomInput
              readOnly={true}
              value={geometryTopWidth}
              onChange={newValue => newValue}
              isNumber={true}
              unit={"mm"}
            />
          </InputContainer>
        </div>

        {
          sections.length > 1 &&
          <div>
            <InputContainer top={heightSection1}>
              <CustomInput
                readOnly={true}
                value={sectionHeights.section3 ? sectionHeights.section3 + sectionHeights.section2 : sectionHeights.section2}
                onChange={newValue => newValue}
                isNumber={true}
                unit={"m"}
              />
              <div className="line"/>
              <CustomInput
                disabled={sectionSelectedInMenu === ""}
                value={sections[0]?.baseWidth ?? ""}
                onChange={newValue => handleInputChange("baseWidth", newValue, 2)}
                isNumber={true}
                unit={"mm"}
              />
            </InputContainer>
          </div>
        }

        {
          sections.length > 2 &&
          <div>
            <InputContainer top={heightSection2}>
              <CustomInput
                readOnly={true}
                value={sectionHeights.section3 || 0}
                onChange={(newValue) => newValue}
                isNumber={true}
                unit={"m"}
              />
              <div className="line"/>
              <CustomInput
                disabled={sectionSelectedInMenu === ""}
                value={sections[1]?.baseWidth ?? ""}
                onChange={newValue => handleInputChange("baseWidth", newValue, 3)}
                isNumber={true}
                unit={"mm"}
              />
            </InputContainer>
          </div>
        }

        <div>
          <InputContainer top={heightSection3}>
            <CustomInput
              readOnly={true}
              value={0}
              onChange={newValue => newValue}
              isNumber={true}
              unit={"m"}
            />
            <div>
              <CustomInput
                readOnly={true}
                value={geometryBaseWidth}
                onChange={newValue => newValue}
                isNumber={true}
                unit={"mm"}
              />
            </div>
            <div className="line"/>
          </InputContainer>
        </div>
      </>
    );
  }

  function renderImages() {
    return imagePaths?.map((imageSrc, index) => {
      return (
        <SegmentImage
          src={imageSrc}
          key={index}
          alt={`Trecho ${index + 1}`}
          isZoomActive={isZoomActive}
        />
      );
    });
  }

  return (
    <SilhouetteContainer>
      <ZoomButtonsContainer isZoomActive={isZoomActive}>
        <button onClick={() => setIsZoomActive(true)}>{" + "}</button>
        <button onClick={() => setIsZoomActive(false)} className="zoomOut">
          {" - "}
        </button>
      </ZoomButtonsContainer>

      <PositionalContainer isZoomActive={isZoomActive}>
        <SectionsContainer isZoomActive={isZoomActive}>
          {renderSectionsContainers()}
        </SectionsContainer>

        <TrussImagesContainer ref={trussesContainerRef} isZoomActive={isZoomActive} imgCount={imagePaths?.length}>
          {renderImages()}
        </TrussImagesContainer>

        <SectionsContainer isZoomActive={isZoomActive}>
          {renderHeightsContainers()}
        </SectionsContainer>
      </PositionalContainer>
    </SilhouetteContainer>
  );
};

export default TowerSilhouetteSections;

const SilhouetteContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    width: 500px;
    height: min-content;
    max-height: calc(100vh - 300px);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    border-radius: 6px;
`;

const ZoomButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: min-content;
    gap: 8px !important;
    z-index: 2;

    button {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: ${props => props.isZoomActive ? colors.kcOrange : colors.kcWhiteColor};
        color: ${props => props.isZoomActive ? colors.kcWhiteColor : colors.kcTextColor};
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        font-weight: 700;

        :hover {
            background-color: ${props => props.isZoomActive ? colors.kcDarkOrange : colors.kcButtonGray};
        }
    }

    .zoomOut {
        background-color: ${props => props.isZoomActive ? colors.kcWhiteColor : colors.kcOrange};
        color: ${props => props.isZoomActive ? colors.kcTextColor : colors.kcWhiteColor};

        :hover {
            background-color: ${props => props.isZoomActive ? colors.kcButtonGray : colors.kcDarkOrange};
        }
    }
`;

const PositionalContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: min-content;
    max-height: calc(100vh - 300px);
    padding-left: 50px;
    box-sizing: border-box;
    overflow-y: ${(props) => (props.isZoomActive ? "auto" : "hidden")} !important;
    overflow-x: hidden;
`;

const TrussImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 130px;
    height: min-content;
    max-height: ${(props) => (props.isZoomActive ? "auto" : "calc(100vh - 320px)")};
    gap: 0;
    padding: 0 50px;
    box-sizing: border-box;
    overflow-x: hidden;

    img {
        height: ${(props) => (props.isZoomActive ? "auto" : "calc(100% / " + props.imgCount + ")")} !important;
    }
`;

const SegmentImage = styled.img`
    width: ${(props) => (props.isZoomActive ? "100px" : "auto")};
    max-width: 100px;
    object-fit: cover;
`;

const SectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 100px;
    height: 100%;
    max-height: ${(props) => (props.isZoomActive ? "auto" : "calc(100vh - 320px)")};
    box-sizing: border-box;
    position: relative;
`;

const SectionInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: ${(props) => props.top}px;
    height: 100%;

    input {
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 60px;
        padding: 3px;
        border-radius: 6px;
        border: 1px solid ${colors.kcBorderGray};
        background-color: ${colors.kcWhiteColor};
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
    }
`;

const InputDisplay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90px;
    height: ${(props) => props.height || "60px"};
    padding: 3px;
    border-radius: 6px;
    border: 1px solid ${colors.kcBorderGray};
    background-color: ${colors.kcWhiteColor};
    font-size: 12px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    line-height: 1.6;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    position: absolute;
    left: 0;
    top: ${(props) => props.top}px;
    
    .line {
        width: ${(props) => props.width || "150px"};
        height: 1px;
        margin-right: 8px;
        background-color: ${colors.kcBorderGray};
        z-index: 2;
    }
    
    input {
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 30px;
        padding: 3px;
        border-radius: 6px;
        border: 1px solid ${colors.kcBorderGray};
        background-color: ${colors.kcWhiteColor};
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
    }
`;
