import { useContext, useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import UserContext from "../../../contexts/UserContext";
import { useTowerPlatforms } from "../../../hooks/api/tower-api/useTowerPlatforms";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import TowerPlatformLeftMenu from "./tower-platformLeftMenu";
import TowerPlatformRightContainer from "./tower-platformRightContainer";
import PlatformsModel from "../../../models/PlatformModel";

export default function TowerPlatformPage() {
  const { userData } = useContext(UserContext);
  const { towerId, geometrySelected, platformsSelected, setPlatformsSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchPlatforms } = useTowerPlatforms("get");
  const { act: savePlatforms } = useTowerPlatforms("post");

  const towerSection = geometrySelected.towerSection;
  const geometryTotalHeight = Number(geometrySelected.totalHeight) || "";
  const initialPlatforms = platformsSelected.map(obj => `Plataforma ${obj.platform}`);

  const [platforms, setPlatforms] = useState(initialPlatforms.length > 0 ? initialPlatforms : ["Plataforma 1"]);
  const [platformSelectedInMenu, setPlatformSelectedInMenu] = useState(1);
  const [inputValues, setInputValues] = useState(new PlatformsModel(platformsSelected).platforms);

  useEffect(() => {
    loadPlatforms().then(response => {
      if (!response || response.length === 0) {
        setInitialEmptyValues();
      } else {
        const platformsModel = PlatformsModel.fromJSON(JSON.stringify(response));
        setPlatformsSelected(platformsModel.platforms);
        setInputValues(platformsModel.platforms);
      }
    });
  }, [towerId, userData.token]);

  const loadPlatforms = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchPlatforms(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados das plataformas.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchPlatforms, towerId, userData.token]);

  function setInitialEmptyValues() {
    let initialData = new PlatformsModel(
      [...Array(platforms.length)].map((_, i) => ({
        platformNumber: i + 1,
        platformType: "",
        platformElevation: "",
      })),
    );
    setInputValues(initialData.platforms);
    setPlatformsSelected(initialData.platforms);
  }

  const platformsTypes = ["Interna", "Externa"];
  const fieldsToCheck = ["platformType", "platformElevation"];
  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], platformsSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value, platform) => {
    setPlatformSelectedInMenu(prev => platform ? platform : prev);

    const platformsModel = new PlatformsModel(inputValues);
    platformsModel.updatePlatform(Number(platformSelectedInMenu), field, value);
    setInputValues(platformsModel.platforms);
  }, [inputValues, platformSelectedInMenu]);

  function addPlatform() {
    if (platforms.length >= 10) {
      return toast.error("Deve haver no máximo 10 plataformas.");
    }
    setPlatforms((prevPlatforms) => [...prevPlatforms, `Plataforma ${prevPlatforms.length + 1}`]);

    const platformsModel = new PlatformsModel([
      ...inputValues,
      {
        platformNumber: platforms.length + 1,
        platformType: "",
        platformElevation: "",
      }
    ]);

    setInputValues(platformsModel.platforms);
    setPlatformSelectedInMenu(platforms.length + 1);
  }

  async function updatePlatformsData() {
    const platformsModel = new PlatformsModel(inputValues);
    if (!platformsModel.validate(geometryTotalHeight)) return false;

    inputValues.map((platform, index) => {
      if(!platform.platformNumber) {
        platform.platformNumber = index + 1;
      }
      return platform;
    });

    setIsLoading(true);
    try {
      const updatedData = await savePlatforms(JSON.stringify(inputValues), towerId, userData.token);
      setPlatformsSelected(updatedData);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das plataformas atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das plataformas.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Plataformas"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updatePlatformsData}
      >
        <TowerPlatformLeftMenu
          platforms={platforms}
          setPlatforms={setPlatforms}
          inputValues={inputValues}
          setInputValues={setInputValues}
          platformSelectedInMenu={platformSelectedInMenu}
          setPlatformSelectedInMenu={setPlatformSelectedInMenu}
          platformsTypes={platformsTypes}
          handleInputChange={(field, value, platform) =>
            handleInputChange(field, value, platform)}
          addPlatform={() => addPlatform()}
        />
      </LeftMenu>
      <RightContainer>
        <TowerPlatformRightContainer
          platforms={platforms}
          inputValues={inputValues}
          platformSelectedInMenu={platformSelectedInMenu}
          setPlatformSelectedInMenu={setPlatformSelectedInMenu}
          handleInputChange={(field, value, platform) =>
            handleInputChange(field, value, platform)}
          addPlatform={() => addPlatform()}
          towerSection={towerSection}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
