import { useCallback, useContext } from "react";
import { TowerContext } from "../contexts/TowerContext";
import { ProjectContext } from "../contexts/ProjectContext";
import { toast } from "react-toastify";
import { userData } from "three/nodes";
import { useTowerSegments } from "./api/tower-api/useTowerSegments";
import GeometryModel from "../models/GeometryModel";
import TrussesModel from "../models/TrussModel";
import SectionsModel from "../models/SectionModel";
import PlatformsModel from "../models/PlatformModel";
import MountsModel from "../models/MountModel";
import DiagonalsModel from "../models/DiagonalModel";
import HorizontalsModel from "../models/HorizontalModel";
import RedundantsModel from "../models/RedundantModel";
import DiagonalLockingsModel from "../models/DiagonalLockingModel";
import HorizontalLockingsModel from "../models/HorizontalLockingModel";
import AnchorsModel from "../models/AnchorModel";
import WindModel from "../models/WindModel";
import AntennasModel from "../models/AntennaModel";

export default function useAssignTowerData() {
  const {
    setTowerData,
    setTowerId,
    setSegmentsSelected,
    setGeometrySelected,
    setTrussesSelected,
    setSectionsSelected,
    setPlatformsSelected,
    setWindSelected,
    setAntennaSelected,
    setMountSelected,
    setDiagonalSelected,
    setHorizontalSelected,
    setRedundantSelected,
    setDiagonalLockingSelected,
    setHorizontaLockingSelected,
    setAnchorsSelected,
  } = useContext(TowerContext);
  const { setIsLoading, setProjectIdSelected } = useContext(ProjectContext);

  const { act: fetchSegmentsId } = useTowerSegments("get");

  const assignTowerData = (data) => {
    // console.log("useAssignTowerData: ", data);
    if (!data || data.length === 0) return;

    setTowerId(data?._id);
    setProjectIdSelected(data?.projectId);
    setTowerData(data);

    if (data?.geometry) {
      getSegmentsId(data?._id).then(response => setSegmentsSelected(response));
    }

    const geometryModel = new GeometryModel(
      data?.geometry?.towerSection,
      data?.geometry?.totalHeight,
      data?.geometry?.topWidth,
      data?.geometry?.baseWidth,
      data?.geometry?.nSegments,
      data?.geometry?.elevation
    );
    setGeometrySelected(geometryModel);

    setTrussesSelected(new TrussesModel( data.segments[0]?.truss
      ? data.segments.map(segment => segment?.truss) : []).trusses);

    setSectionsSelected(new SectionsModel(data.sections ?? []).sections);

    setPlatformsSelected(new PlatformsModel(data.platforms ?? []).platforms);

    setWindSelected(new WindModel(data?.wind || {}).wind);

    setAntennaSelected(new AntennasModel({
      antennas: data?.antennas || [],
      cableTrayLength: data?.cableTrayLength || "",
    }).antennasData);

    setMountSelected(new MountsModel(data.segments[0]?.mount
      ? data.segments.map(segment => segment?.mount) : []).mounts);

    setDiagonalSelected(new DiagonalsModel(data.segments[0]?.diagonal
      ? data.segments.map(segment => segment.diagonal) : []).diagonals);

    setHorizontalSelected(new HorizontalsModel(data.segments[0]?.horizontal
      ? data.segments.map(segment => segment.horizontal) : []).horizontals);

    setRedundantSelected(new RedundantsModel(data.segments[0]?.redundant
      ? data.segments.map(segment => segment.redundant) : []).redundants);

    setDiagonalLockingSelected(new DiagonalLockingsModel(data.segments[0]?.diagonalLocking
      ? data.segments.map(segment => segment.diagonalLocking) : []).diagonalLockings);

    setHorizontaLockingSelected(new HorizontalLockingsModel(data.segments[0]?.horizontalLocking
      ? data.segments.map(segment => segment.horizontalLocking) : []).horizontalLockings);

    setAnchorsSelected(new AnchorsModel(data?.anchors || {}).anchor);
  };

  const getSegmentsId = useCallback(async(towerId) => {
    setIsLoading(true);
    try {
      const segments = await fetchSegmentsId(towerId, userData.token);
      if (!segments || segments.length === 0) {
        return [];
      }
      return segments;
    } catch (error) {
      toast.error("Erro ao buscar dados dos segmentos.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchSegmentsId, userData.token]);

  return assignTowerData;
}
